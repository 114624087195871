/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const ReactGA = require('react-ga4').default
const { smoothAnchorate } = require('smooth-anchorate')
const SmoothScroll = require('smooth-scroll')

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')

// Initialize GA4 with our tracking id
ReactGA.initialize(process.env.GATSBY_GA4_TRACKING_ID)

exports.onRouteUpdate = () => {
  SmoothScroll('a[href*="#"]')
  // Smooth scroll setup on every link click created with '<a />' tag
  smoothAnchorate()
}
